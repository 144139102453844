import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import PreloaderLogo from './logo.gif';

import './preloader.scss';

const Preloader = () => {
    const location = useLocation(); // Use location from react-router
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true); // Show the preloader when location changes
        const timeout = setTimeout(() => {
            setLoading(false); // Hide the preloader after a delay
        }, 1000); // Adjust timing as needed

        return () => clearTimeout(timeout); // Cleanup timeout
    }, [location.pathname, location.search]); // Trigger on location change

    return (
        <Box className={`preloader ${loading ? 'active' : 'fade-out'}`}>
            <img src={PreloaderLogo} alt='' style={{
                width: 300,
                maxWidth: '100%'
            }} />
        </Box>
    );
};

export default Preloader;
