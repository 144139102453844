import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Grid from '@mui/material/Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Parallax } from 'react-parallax';
import FsLightbox from "fslightbox-react";
import { WOW } from 'wowjs';

import { Keyboard, Pagination, Navigation } from 'swiper/modules';
import CardActionArea from '@mui/material/CardActionArea';
import MarqueeScroll from '../components/marque/marque.jsx';
import Footer from '../components/footer/footer.jsx';
import { GlobalTheme } from '../theme';
import axios from 'axios'; // For API request
import { useLocation } from 'react-router-dom'; // For getting query params
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Link from '@mui/material/Link';
import { Link as bAlink } from 'react-router-dom';
import HomeStyle from './assets/scss/style.module.scss';
import useMediaQuery from '@mui/material/useMediaQuery';
import './assets/scss/mantle.scss';
import { Helmet } from 'react-helmet';
const popModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', lg: '40%' },
    p: 0,
    outline: 'none'
};

const PlpCategory = ({ gridHeight }) => {
    const [animate, setAnimate] = useState(false);
    const plHeBaRef = useRef(null);
    const isDesktop = useMediaQuery(GlobalTheme.breakpoints.up('lg'));
    const [scrollY, setScrollY] = useState(0);

    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [category, setCategory] = useState(null);
    const [subcategories, setSubcategories] = useState([]);
    const [products, setProducts] = useState([]); // Store all products
    const [curImageLight, setcurImageLight] = useState(''); // Store all products
    const location = useLocation();
    const [toggler, setToggler] = useState(false);

    const handleClose = () => setOpen(false);
    const handleOpen = (productIndex) => {
        setCurrentIndex(productIndex);
        setOpen(true);
    };

    const baseImageUrl = 'https://banka1964.com/api/cmsapi/uploads';
    // const handleOpen = (productIndex, productSource) => {
    //     const imageLight = `${baseImageUrl}/${productSource}`;
    //     if (!toggler) {
    //         setcurImageLight(imageLight); // Set the selected image as the source for the lightbox
    //         setCurrentIndex(productIndex); // Track the current image index (if needed for other logic)
    //         setToggler(true); // Toggle the lightbox
    //     }
    // };
    // Extract category_id and subcategory_id from URL query params
    const getCategoryIdFromUrl = () => {
        const params = new URLSearchParams(location.search);
        return params.get('category_id');
    };

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Fetch category, subcategories, and products
    useEffect(() => {
        const category_id = getCategoryIdFromUrl();
        if (category_id) {
            // Fetch the category
            axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_category`)
                .then((categoryResponse) => {
                    const categoryData = categoryResponse.data.data.find(cat => cat.id === parseInt(category_id));
                    setCategory(categoryData); // Set category info
                })
                .catch((error) => console.error('Error fetching category:', error));

            // Fetch subcategories and products
            const fetchSubcategories = axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_sub_category`);
            const fetchProducts = axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_product`);

            // Once both subcategories and products are fetched, filter and set the data
            Promise.all([fetchSubcategories, fetchProducts])
                .then(([subCategoryResponse, productResponse]) => {
                    const filteredSubcategories = subCategoryResponse.data.data.filter(sub => sub.category_id === parseInt(category_id));
                    setSubcategories(filteredSubcategories); // Set filtered subcategories
                    setProducts(productResponse.data.data); // Set all products
                })
                .catch((error) => {
                    console.error('Error fetching subcategories or products:', error);
                });
        }
    }, [location]);

    const openLightbox = (index) => {
        setCurrentIndex(index);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setLightboxOpen(false);
    };
    let globalProductIndex = 0;

    useEffect(() => {
        if (plHeBaRef.current) {
            plHeBaRef.current.style.height = `${gridHeight}px`; // Set height from prop
        }
        console.log(gridHeight)
    }, [gridHeight]); // Recalculate on gridHeight change

    useEffect(() => {
        // Trigger animation once the component mounts
        const timeout = setTimeout(() => {
            setAnimate(true);
        }, 0); // Delay can be adjusted as needed
        return () => clearTimeout(timeout); // Cleanup
    }, []);

    useEffect(() => {
        const wow = new WOW({
            boxClass: 'wow',       // Class to identify elements to animate
            animateClass: 'animate__animated', // Animation class from Animate.css
            offset: 100,           // Distance to start the animation (in px)
            mobile: false,          // Enable animations on mobile
            live: true,            // Recheck for new elements to animate on the page
        });
        wow.init(); // Initialize WOW.js
    }, []);
    return (
        <>
            <Grid container spacing={2}>
                {/* Category Banner Section */}
                <Box id='plMaBa' ref={plHeBaRef} component='section' aria-labelledby='plpBa' width='100%' position='relative'>
                    {category && (
                        <>
                            <Helmet>
                                <title>{category.category || ' '} : Banka since 1964</title>
                                <meta name="description" content={category.category_seo_description || ' '} />
                                <meta name="keywords" content={category.category_seo_keywords || ' '} />
                            </Helmet>
                            <Card sx={{ position: 'relative', height: 'inherit', borderRadius: 0 }}
                            >
                                <Parallax
                                    blur={isDesktop ? { min: Math.min(scrollY / 10, -15), max: Math.min(scrollY / 5, 15) } : ''}
                                    strength={-100}

                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        display: "flex",
                                    }}
                                    bgImage={`${baseImageUrl}/${isDesktop ? category.category_banner : category.category_mobile_banner}`}
                                    title={category.category_heading || ''}
                                >
                                </Parallax>
                                <Container fixed>
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            bottom: {
                                                xs: 40,
                                                lg: 88
                                            },
                                        }}>
                                        <Typography id='plpBa' marginBottom={4.375} variant="h2" component="h1" color='common.white'
                                            className={animate && isDesktop ? 'animate__animated animate__fadeInLeft animate__delay-1s' : ''}
                                            data-wow-duration="1s"
                                            data-wow-delay="2s"
                                            data-wow-offset='100'
                                            data-wow-iteration='1'

                                            sx={{
                                                "&:after": {
                                                    content: '""',
                                                    width: '36%',
                                                    height: '1px',
                                                    display: 'block',
                                                    marginTop: 2,
                                                    backgroundColor: GlobalTheme.palette.common.white
                                                }
                                            }}>
                                            {category.category_heading}
                                        </Typography>
                                        <Typography  variant='body2' color='common.white' fontWeight={200}
                                            className={animate && isDesktop ? 'animate__animated animate__fadeInUp animate__delay-2s' : ''}
                                            data-wow-duration="1s"
                                            data-wow-delay="2s"
                                            data-wow-offset='100'
                                            data-wow-iteration='1'>
                                            {category.category_description}
                                        </Typography>
                                    </Box>
                                </Container>
                            </Card>
                        </>
                    )}
                </Box>

                <Container maxWidth='false' disableGutters id='scBa' component='section' >
                    <Container maxWidth='false' disableGutters sx={{ justifyContent: 'center', textAlign: 'center', pt: { xs: 4.625, lg: 15.87 }, pb: 6.625 }}
                    // className="wow animate__animated animate__fadeInUp"
                    // data-wow-duration="1s"
                    // data-wow-delay="1s"
                    // data-wow-offset='100'
                    // data-wow-iteration='1'
                    >
                        <Typography component='h2' variant='h2' color='primary' position='relative'
                            sx={{
                                position: 'relative',
                                fontSize: {
                                    xs: '1.375rem',
                                    lg: '2.25rem'
                                },
                                "&:before": {
                                    content: '""',
                                    height: 2,
                                    width: '100%',
                                    position: 'absolute',
                                    transform: {
                                        xs: 'translateY(12px)',
                                        lg: 'translateY(22px)'
                                    },
                                    backgroundColor: 'primary.main',
                                    left: 0, right: 0
                                }
                            }}>
                            <Box component='span' position='relative' display='flex' justifyContent='center' sx={{
                                backgroundColor: GlobalTheme.palette.common.white,
                                width: {
                                    xs: '70%',
                                    lg: '25%'
                                },
                                zIndex: 1, margin: '0 auto'
                            }}>
                                Spectrum of {category?.category}
                            </Box>
                        </Typography>
                    </Container>


                    {/* Subcategories Loop with Products */}
                    {subcategories.map((subcategory, index) => (
                        <Box key={index} component='section' aria-labelledby='subcategory' width='100%' marginTop={1.25} position='relative'
                            sx={{
                                '&:hover': {
                                    '.plImBa': {
                                        filter: 'brightness(0.6)',
                                    }
                                }
                            }}>
                            <Card id='caMeBa' sx={{ borderRadius: 0, position: 'relative' }}

                            >
                                <CardMedia
                                    className="plImBa"
                                    // data-wow-duration="1s"
                                    // data-wow-delay="1s"
                                    // data-wow-offset='100'
                                    // data-wow-iteration='1'
                                    sx={{
                                        height: 361,
                                        backgroundPosition: {
                                            lg: 'left bottom'
                                        },
                                        transition: 'filter 0.3s ease-in-out',
                                    }}
                                    image={`${baseImageUrl}/${isDesktop ? subcategory.sub_category_thumbnail : subcategory.collection_banner_mobile}`}
                                    title={subcategory.sub_category_heading}
                                    loading='lazy'
                                />
                                <Container fixed>
                                    <CardContent
                                        sx={{
                                            display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
                                            position: 'absolute', top: 0, bottom: 0, left: 0, right: 0,
                                            px: { xs: 10, lg: 0 },
                                            margin: '0 auto', textAlign: 'center', zIndex: 1
                                        }}
                                    >
                                        <Typography gutterBottom component="h3" color='common.white'
                                            sx={{
                                                fontSize: {
                                                    xs: '1.375rem',
                                                    lg: '2.25rem'
                                                },
                                            }}>
                                            {subcategory.sub_category_heading}
                                        </Typography>
                                        <Link component={bAlink} to={`/plp?subcategory_id=${subcategory.id}`} underline="none" className={HomeStyle['slide-link']}>
                                            <Typography component='span' variant='subtitle1' fontWeight={500} className={HomeStyle['link-text']}>View</Typography>
                                        </Link>
                                        {/* <Button variant='text'>View</Button> */}
                                        {/* <Typography variant="body2" color='common.white'>
                                            {subcategory.sub_category_description}
                                        </Typography> */}
                                    </CardContent>
                                </Container>
                            </Card>

                            {/* Conditionally render the Product Grid if show_in_collection === 1 */}
                            {subcategory.show_in_collection === 1 && (
                                <Container fixed sx={{ pt: 9.125, pb: 8.125 }}
                                // className="wow animate__animated animate__fadeIn"
                                // data-wow-duration="1s"
                                // data-wow-delay="1s"
                                // data-wow-offset='100'
                                // data-wow-iteration='1'
                                >
                                    <Grid container spacing={4} justifyContent='center'>
                                        {products.filter(product => product.sub_category_id === subcategory.id && product.show_in_collection === 1).length > 0 ? (
                                            products
                                                .filter(product => product.sub_category_id === subcategory.id && product.show_in_collection === 1)
                                                .map((product) => {
                                                    // Increment the productIndex globally to prevent it from resetting in each subcategory
                                                    let productIndex = globalProductIndex++;

                                                    return (
                                                        <Grid item size={{ xs: 6, sm: 4, lg: 4 }} key={productIndex}>
                                                            <Card
                                                                elevation={0}
                                                                id={`${productIndex}`}
                                                                sx={{ background: GlobalTheme.palette.general.grayLighter, overflow: 'hidden', borderRadius: 2.5 }}
                                                                position='relative'
                                                            >
                                                                <CardActionArea
                                                                    onClick={() => handleOpen(productIndex)}
                                                                    // onClick={() => handleOpen(productIndex, product.product_image)}
                                                                    sx={{
                                                                        p: {
                                                                            xs: 4.75,
                                                                            lg: 10.375
                                                                        },
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        transition: 'transform 2s ease',
                                                                        cursor: 'pointer',
                                                                        '&:hover': {
                                                                            transform: 'scale(2.2)',
                                                                        },
                                                                    }}>
                                                                    <CardMedia
                                                                        component="img"
                                                                        image={`${baseImageUrl}/${product.product_image}`}
                                                                        alt={product.product_name}
                                                                        loading='lazy'
                                                                    />
                                                                </CardActionArea>
                                                            </Card>
                                                        </Grid>
                                                    );
                                                })
                                        ) : (
                                            <Typography variant="body2" color="textSecondary">
                                                No products available for this subcategory.
                                            </Typography>
                                        )}
                                    </Grid>
                                </Container>
                            )}
                        </Box>
                    ))}
                </Container>
            </Grid >

            <MarqueeScroll />
            <Footer />
            {/* <FsLightbox
                toggler={toggler}
                sources={[curImageLight]} // Pass the selected image in an array format
                slide={1} // Always show the first slide as it's a single image
                onClose={() => setToggler(false)}
            /> */}
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                        sx: {
                            backdropFilter: 'blur(10px)', // Adjust the blur intensity
                            backgroundColor: 'rgba(GlobalTheme.palette.secondary.lightest, 0.6)', // Optional: Add some darkening effect
                        },
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={popModal}>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            keyboard={{ enabled: true }}
                            navigation={false}
                            modules={[Keyboard, Pagination, Navigation]}
                            initialSlide={currentIndex}
                            className='plpSwiper'
                            style={{
                                "& .swiper-slide": {
                                    height: 'auto'
                                }
                            }}
                        >
                            {subcategories.map((subcategory, index) => (
                                subcategory.show_in_collection === 1 && (
                                    products.filter(product => product.sub_category_id === subcategory.id && product.show_in_collection === 1).length > 0 ? (
                                        <Swiper key={index}
                                            style={{
                                                outline: 'none'
                                            }}
                                        >
                                            {products
                                                .filter(product => product.sub_category_id === subcategory.id && product.show_in_collection === 1)
                                                .map((product, productIndex) => (
                                                    <SwiperSlide id={`${productIndex}`} key={productIndex}>
                                                        <img
                                                            src={`${baseImageUrl}/${product.product_image}`}
                                                            alt={product.product_name}
                                                            style={{ width: '100%', height: '100%' }}
                                                        />
                                                    </SwiperSlide>
                                                ))}
                                        </Swiper>
                                    ) : (
                                        <Typography variant="body2" color="textSecondary" key={index}>
                                            No products available for this subcategory.
                                        </Typography>
                                    )
                                )
                            ))}

                        </Swiper>
                    </Box>
                </Fade>
            </Modal>

        </>
    );
};

export default PlpCategory;
