import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';

import { visuallyHidden } from '@mui/utils';
import Container from '@mui/material/Container';
import { Pagination, Autoplay, Parallax } from 'swiper/modules';

import useMediaQuery from '@mui/material/useMediaQuery';
import { CardMedia, Typography, Box } from "@mui/material";
import HomeStyle from './assets/scss/style.module.scss';

import { GlobalTheme } from "../theme.js";
import Link from '@mui/material/Link';
import { Link as bAlink } from 'react-router-dom';

import './assets/scss/popularView.scss';

const Home = ({ gridHeight }) => {
    const isDesktop = useMediaQuery(GlobalTheme.breakpoints.up('lg'));
    const baseImageUrl = 'https://bankacom.inpro1.fcomet.com/api/cmsapi/uploads';
    const baseBImageUrl = 'https://bankacom.inpro1.fcomet.com/api/backend/uploads';
    const [scategories, setsCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [progress, setProgress] = useState(0);
    const swiperRef = useRef(null);
    const progressValues = useRef(new Array(15).fill(0));
    const [categories, setCategories] = useState([]);
    const categoryIdFromUrl = 1;  // This should come from your logic to extract from the URL
    const items = Array.from({ length: 15 }, (_, index) => ({
        id: index + 1,
        image: `https://via.placeholder.com/150?text=Item+${index + 1}`,
        title: `Item ${index + 1}`,
    }));

    const progressCircle = useRef(null);
    const progressContent = useRef(null);

    const onAutoplayTimeLeft = (_, timeLeft, progressRatio) => {
        const currentSlideIndex = swiperRef.current.realIndex;
        progressValues.current[currentSlideIndex] = progressRatio * 100;
        setProgress(progressRatio); // Update progress (normalized between 0 and 1)
    };

    const handlePaginationClick = (index) => {
        if (swiperRef.current) {
            swiperRef.current.slideTo(index); // Navigate to the corresponding slide
        }
    };


    // Filter subcategories and products for the category where `product.show_in_spectrum === 1`
    const filteredProducts = products.filter(product => {
        // Find the subcategory that matches the category_id from URL and show_in_spectrum flag
        const subcategory = subcategories.find(subcat => subcat.id === product.sub_category_id && subcat.category_id === categoryIdFromUrl);

        // Check if the product belongs to this category and is marked as show_in_spectrum
        return subcategory && product.show_in_spectrum === 1;
    });

    // filteredProducts now contains all products for the category where show_in_spectrum === 1
    console.log(filteredProducts);
    const [slidesData, setSlidesData] = useState([]);
    const fetchSlidesData = async () => {
        try {
            const response = await axios.get('https://bankacom.inpro1.fcomet.com/backend/table-data/banka_valley_banner');
            const filteredData = response.data.data.filter(slide => slide.id !== 1); // Filter out records where id === 1
            setSlidesData(filteredData); // Save the filtered data
        } catch (error) {
            console.error('Error fetching valley banner data:', error);
        }
    };

    useEffect(() => {
        const fetchCategories = axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_category`);


        Promise.all([fetchCategories])
            .then(([categoryResponse]) => {
                setCategories(categoryResponse.data.data);

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    useEffect(() => {
        const fetchCategories = axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_category`);
        const fetchSubcategories = axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_sub_category`);
        const fetchProducts = axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_product`);


        Promise.all([fetchCategories, fetchSubcategories, fetchProducts])
            .then(([categoryResponse, subcategoryResponse, productResponse]) => {
                setsCategories(categoryResponse.data.data);
                setSubcategories(subcategoryResponse.data.data);
                setProducts(productResponse.data.data);

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    useEffect(() => {
        fetchSlidesData();

    }, []);


    return (
        <>
            <Container disableGutters maxWidth={false} component='section' aria-labelledby="pro"
                // className="wow animate__animated animate__fadeIn"
                // data-wow-duration="1s"
                // data-wow-delay="1s"
                // data-wow-offset='100'
                // data-wow-iteration='1'
                >
                <Typography id='pro' component='h2' sx={{ ...visuallyHidden }}>Products</Typography>

                <Swiper
                    ref={swiperRef}
                    onSwiper={(swiper) => (swiperRef.current = swiper)}
                    className='popularView'
                    centeredSlides={true}
                    pauseOnMouseEnter
                    waitForTransition
                    parallax={true}
                    spaceBetween={0}
                    speed={600}
                    autoplay={{
                        delay: 4500,
                        disableOnInteraction: true,
                    }}
                    pagination={{
                        clickable: true,
                        renderBullet: (index, className) => {
                            return `<span class="${className} ${HomeStyle.customBullet}"></span>`;
                        },
                    }}
                    modules={[Pagination, Autoplay, Parallax]}
                    onAutoplayTimeLeft={onAutoplayTimeLeft}
                >
                    {categories.map((category, index) => (
                        <SwiperSlide data-swiper-autoplay="5000" key={index} id={`pP${index}`}>
                            <Box sx={{
                                transition: 'all 5s ease-in',

                                "&:hover": {
                                    "& .zoom": {
                                        transform: 'scale(1.06)',
                                    }
                                }
                            }}>
                                <Box
                                    key={index}
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        zIndex: 1,
                                        flexDirection: 'column',

                                    }}>
                                    <Box data-swiper-parallax="-500" >
                                        <Typography component='h2' variant='h2' fontWeight={400} color={GlobalTheme.palette.common.white} marginBottom={4}>Popular Picks</Typography>
                                    </Box>
                                    <Box data-swiper-parallax="-100">
                                        <Link component={bAlink} to={'/PopularProduct?category_id=' + category.id} underline="none" className={HomeStyle['slide-link']}>
                                            <Typography component='span' variant='subtitle1' fontWeight={500} color='common.white' className={HomeStyle['link-text']}>View</Typography>
                                        </Link>
                                    </Box>
                                </Box>

                                {category.type === 'video' ? (
                                    <CardMedia
                                        id={`pPV${index}`}
                                        component="video"
                                        src={baseImageUrl + "/" + category.category_banner}
                                        autoPlay
                                        loop
                                        muted
                                        playsInline
                                        sx={{
                                            maxHeight: 621, objectFit: 'cover',
                                            transition: 'scale .3s ease-in',
                                            "&:hover": {
                                                transform: 'scale(1.2)'
                                            }
                                        }}
                                    />
                                ) : (
                                    <CardMedia
                                        id="mantleMedia"
                                        component="img"
                                        className='zoom'
                                        data-swiper-parallax="0%"
                                        image={`${baseImageUrl}/${isDesktop ? category.category_popular_pick_banner : category.category_mobile_popular_pick_banner}`}
                                        alt={category.category}
                                        title={category.category_heading}
                                        loading='lazy'
                                        sx={{
                                            transition: 'transform 0.6s ease-in-out',
                                            height: 621
                                        }}
                                    />
                                )}
                            </Box>
                        </SwiperSlide>
                    ))}

                    {/* <div className="progress-bar-container">
                        {categories.map((_, index) => (
                            <div
                                key={index}
                                className="progress-bar"
                                style={{
                                    '--progress-width': `${swiperRef.current?.realIndex === index ? progress * 100 : 0}%`,
                                }}
                                onClick={() => swiperRef.current.slideTo(index)} // Navigate to the corresponding slide
                            />
                        ))}
                    </div> */}
                </Swiper>
            </Container >
        </>
    )
}

export default Home;