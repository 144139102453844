import React, { useState, useEffect } from "react";
import axios from "axios";
import Footer from '../components/footer/footer.jsx';
import { GlobalTheme } from '../theme';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { WOW } from 'wowjs';

import { Button, Stack, Typography, Snackbar, Alert, TextField, Link } from "@mui/material";
import HomeStyle from './assets/scss/style.module.scss';
import BankaLogo from './assets/images/connectBg.svg';
import ConnectStyle from './assets/scss/connect.module.scss';
import HelMet from "../components/helmet.jsx";
const Connect = () => {
    const [formData, setFormData] = useState({
        name: '',
        businessName: '',
        email: '',
        phone: '',
        location: '',
        message: ''
    });
    const [errors, setErrors] = useState({});
    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOtp] = useState('');
    const [generatedOtp, setGeneratedOtp] = useState('');
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = () => {
        let newErrors = {};
        if (!formData.name) newErrors.name = 'Your name is required';
        if (!formData.businessName) newErrors.businessName = 'Business name is required';
        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Invalid email format';
        }
        if (!formData.phone) {
            newErrors.phone = 'Phone number is required';
        } else if (!/^\d{10}$/.test(formData.phone)) {
            newErrors.phone = 'Phone number must be 10 digits';
        }
        if (!formData.location) newErrors.location = 'Location is required';
        if (!formData.message) newErrors.message = 'Message is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleOtpRequest = async () => {
        if (!validateForm()) return;

        const generatedOtpValue = Math.floor(100000 + Math.random() * 900000).toString();
        setGeneratedOtp(generatedOtpValue);

        try {
            await axios.post('https://bankacom.inpro1.fcomet.com/cmsapi/connect/send-otp', { email: formData.email, otp: generatedOtpValue });
            setSnackbar({ open: true, message: 'OTP sent to your email', severity: 'success' });
            setOtpSent(true);
        } catch (error) {
            setSnackbar({ open: true, message: 'Failed to send OTP.', severity: 'error' });
        }
    };

    const handleOtpSubmit = async () => {
        if (otp !== generatedOtp) {
            setSnackbar({ open: true, message: 'Incorrect OTP.', severity: 'error' });
            return;
        }

        try {
            const response = await axios.post('https://bankacom.inpro1.fcomet.com/cmsapi/connect/verify', formData);
            setSnackbar({ open: true, message: response.data.message, severity: 'success' });
            setFormData({ name: '', businessName: '', email: '', phone: '', location: '', message: '' });
            setOtpSent(false);
            setOtp('');
            setGeneratedOtp('');
        } catch (error) {
            setSnackbar({ open: true, message: 'Failed to submit message.', severity: 'error' });
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ open: false, message: '', severity: 'success' });
    };

    useEffect(() => {
        const wow = new WOW({
            boxClass: 'wow',       // Class to identify elements to animate
            animateClass: 'animate__animated', // Animation class from Animate.css
            offset: 100,           // Distance to start the animation (in px)
            mobile: false,          // Enable animations on mobile
            live: true,            // Recheck for new elements to animate on the page
        });
        wow.init(); // Initialize WOW.js
    }, []);

    return (
        <>
            <HelMet page_name="connect" />
            <Container fixed>
                <Box borderRadius={2.5} marginTop={7.375} position='relative' sx={{
                    py: {
                        xs: 5.625,
                        lg: 6.25
                    },
                    px: {
                        xs: 5.625,
                        lg: 8.125
                    },
                    background: `linear-gradient(267deg, ${GlobalTheme.palette.secondary.main} 0%, ${GlobalTheme.palette.secondary.darker} 100%)`,
                    '&:after': {
                        backgroundImage: `url(${BankaLogo})`,
                        backgroundRepeat: 'no-repeat',
                        content: '""',
                        width: 145,
                        height: 214,
                        display: 'block',
                        position: 'absolute', right: 0, top: 0,
                    }
                }}
                // className="wow animate__animated animate__fadeIn"
                // data-wow-duration="1s"
                // data-wow-delay="1s"
                // data-wow-offset='100'
                // data-wow-iteration='1'
                >
                    <Typography component='address' fontStyle='normal' marginTop={{ xs: 16.75, sm: 0 }}>
                        <Typography component='p' fontWeight={300} color="common.white" gutterBottom sx={{
                            fontSize: {
                                xs: '.875rem',
                                lg: '1.375rem'
                            }
                        }}>Banka Gem and Jewellery</Typography>
                        <Typography component='p' variant="subtitle1" fontWeight={200} color="common.white" gutterBottom sx={{
                            // fontSize: {
                            //     xs: '.875rem',
                            //     lg: '1.375rem'
                            // }
                        }}>2nd Floor Balaji Tower, <br />135 Cotton Street Kolkata 700 007</Typography>
                    </Typography>
                    <Typography component='p' variant="subtitle1" fontWeight={200} color="common.white" sx={{
                        // fontSize: {
                        //     xs: '.875rem',
                        //     lg: '1.375rem'
                        // }
                    }}>Call: <Link href='tel:+91 82530 29120' color="common.white" underline="hover">82530 29120</Link></Typography>
                </Box>

                <Box marginTop={4.375}>
                    <iframe
                        title="Bank shop address"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3683.9350894170834!2d88.35235197599917!3d22.581530932641645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0277d67f0766a7%3A0xa1a677793bbb38d0!2sBanka%20Gem%20%26%20Jewellery!5e0!3m2!1sen!2sin!4v1730059173261!5m2!1sen!2sin" width="100%" height="522" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </Box>
            </Container>

            <Container maxWidth='false' disableGutters sx={{ justifyContent: 'center', textAlign: 'center', pt: 7.37, pb: 6.625 }}>
                <Typography component='h2' variant='h2' color='secondary' position='relative'
                    // className="wow animate__animated animate__fadeIn"
                    // data-wow-duration="1s"
                    // data-wow-delay="1s"
                    // data-wow-offset='100'
                    // data-wow-iteration='1'
                    sx={{
                        position: 'relative',
                        fontSize: {
                            xs: '1.375rem',
                            lg: '2.25rem'
                        },

                        "&:before": {
                            content: '""',
                            height: 2,
                            width: '100%',
                            position: 'absolute',
                            transform: {
                                xs: 'translateY(12px)',
                                lg: 'translateY(22px)'
                            },
                            backgroundColor: 'secondary.main',
                            left: 0, right: 0
                        }
                    }}>
                    <Box component='span' position='relative' display='flex' justifyContent='center' sx={{
                        backgroundColor: GlobalTheme.palette.common.white,
                        width: {
                            xs: '70%',
                            sm: '35%',
                            md: '30%',
                            lg: '30%'
                        },
                        zIndex: 1, margin: '0 auto'
                    }}>
                        Let’s Get in Touch
                    </Box>
                </Typography>
            </Container>

            <Container fixed sx={{
                mb: 18.75,
                px: { xs: 3.375, }
            }}
            // className="wow animate__animated animate__slideIn"
            // data-wow-duration="1s"
            // data-wow-delay="1s"
            // data-wow-offset='100'
            // data-wow-iteration='1'
            >
                <Stack component="form" onSubmit={(e) => e.preventDefault()}>
                    <TextField
                        name="name"
                        label="Your Name :"
                        variant="standard"
                        value={formData.name}
                        onChange={handleChange}
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                        sx={{ mb: 4.375 }}
                    />
                    <TextField
                        name="businessName"
                        label="Business Name :"
                        variant="standard"
                        value={formData.businessName}
                        onChange={handleChange}
                        error={Boolean(errors.businessName)}
                        helperText={errors.businessName}
                        sx={{ mb: 4.375 }}
                    />
                    <TextField
                        name="email"
                        label="Email :"
                        variant="standard"
                        value={formData.email}
                        onChange={handleChange}
                        error={Boolean(errors.email)}
                        helperText={errors.email}
                        sx={{ mb: 4.375 }}
                    />
                    <TextField
                        name="phone"
                        label="Phone :"
                        variant="standard"
                        value={formData.phone}
                        onChange={handleChange}
                        error={Boolean(errors.phone)}
                        helperText={errors.phone}
                        sx={{ mb: 4.375 }}
                    />
                    <TextField
                        name="location"
                        label="Location :"
                        variant="standard"
                        value={formData.location}
                        onChange={handleChange}
                        error={Boolean(errors.location)}
                        helperText={errors.location}
                        sx={{ mb: 4.375 }}
                    />
                    <TextField
                        name="message"
                        label="Message :"
                        variant="standard"
                        value={formData.message}
                        onChange={handleChange}
                        error={Boolean(errors.message)}
                        helperText={errors.message}
                        sx={{ mb: 4.375 }}
                        multiline
                        minRows={2}
                        maxRows={6}
                    />

                    {/* OTP Verification Section */}
                    {otpSent ? (
                        <>
                            <TextField
                                label="Enter OTP"
                                variant="standard"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                sx={{ mb: 4.375 }}
                                fullWidth
                            />
                            <Box sx={{ px: 5, textAlign: 'center' }}>
                                <Button onClick={handleOtpSubmit} variant="contained" className={`${ConnectStyle.btnStyle} ${ConnectStyle.connectBtn}`}
                                    sx={{
                                        width: { xs: '100%', lg: 'auto' },
                                        py: 1.875,
                                        px: 3.125,
                                        borderRadius: 7.5,
                                        backgroundColor: GlobalTheme.palette.common.white,
                                        fontWeight: 400,
                                        color: '#0E1D42'
                                    }}
                                >
                                    Verify OTP and Submit
                                </Button>
                            </Box>
                        </>
                    ) : (
                        <Box sx={{ px: 5, textAlign: 'center' }}>
                            <Button onClick={handleOtpRequest} variant="contained" className={`${ConnectStyle.btnStyle} ${ConnectStyle.connectBtn}`}
                                sx={{
                                    width: { xs: '100%', lg: 'auto' },
                                    py: 1.875,
                                    px: 3.125,
                                    borderRadius: 7.5,
                                    backgroundColor: GlobalTheme.palette.common.white,
                                    fontWeight: 400,
                                    color: '#0E1D42'
                                }}
                            >
                                Submit
                            </Button>
                        </Box>
                    )}
                </Stack>
            </Container>

            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <Footer />
        </>
    );
};

export default Connect;
