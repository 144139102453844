import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Autoplay } from 'swiper/modules';
import { useLocation } from 'react-router-dom';
import './assets/scss/event.scss';


const EventSlider = () => {
    const [eventdata, setEventData] = useState([]);
    const [eventparticipation, setEventParticipation] = useState([]);
    const [eventsnap, setEventSnap] = useState([]);

    const baseImageUrl = 'https://bankacom.inpro1.fcomet.com/api/cmsapi/uploads';
    const baseBImageUrl = 'https://bankacom.inpro1.fcomet.com/api/backend/uploads';

    // Fetch categories, subcategories, and products
    useEffect(() => {

        const eventdata = axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_event_data`);
        const eventparticipation = axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_event_participation`);
        const eventsnap = axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_event_snapshots`);

        Promise.all([eventdata, eventparticipation, eventsnap])
            .then(([edResponse, epResponse, esRespomse]) => {

                const filteredeventdata = edResponse.data.data.filter(about => about.id !== 1); // Filter out records where id === 1
                const filteredeventparticipation = epResponse.data.data.filter(about => about.id !== 1); // Filter out records where id === 1
                const filteredeventsnap = esRespomse.data.data.filter(about => about.id !== 1); // Filter out records where id === 1

                setEventData(filteredeventdata);
                setEventParticipation(filteredeventparticipation);
                setEventSnap(filteredeventsnap);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    return (
        <Swiper
            slidesPerView={4}
            spaceBetween={30}
            modules={[Autoplay]}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}

            breakpoints={{
                0: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                900: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                }
            }}
        >
            {eventparticipation.map((item) => (
                <SwiperSlide style={{
                    margin: '0 auto',
                    width: '70%',

                    "@media screen and (min-width: 1200)": {
                        width: '100%'
                    }

                }}>
                    {item.image_event_logo && (
                        <img
                            src={`${baseBImageUrl}/${item.image_event_logo}`}
                            alt=""
                            style={{
                                width: '100%'
                            }}
                        />
                    )}
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default EventSlider;
