import React, { useEffect } from 'react'; import Marquee from "react-fast-marquee";
import { visuallyHidden } from '@mui/utils';
import { WOW } from 'wowjs';
import Container from '@mui/material/Container';
import Marque from './assets/images/mIi.png';
import { Typography } from "@mui/material";

const MarqueeScroll = () => {

    useEffect(() => {
        const wow = new WOW({
            boxClass: 'wow',       // Class to identify elements to animate
            animateClass: 'animate__animated', // Animation class from Animate.css
            offset: 100,           // Distance to start the animation (in px)
            mobile: false,          // Enable animations on mobile
            live: true,            // Recheck for new elements to animate on the page
        });
        wow.init(); // Initialize WOW.js
    }, []);

    return (
        <Container maxWidth={false} component='section' aria-labelledby="prom" sx={{
            pt: {
                xs: 2.5,
                lg: 8

            },
            pb: {
                xs: 2.5
            },

            // mb: {
            //     xs: 8.125
            // }
        }}
            // className="wow animate__animated animate__fadeIn"
            // data-wow-duration="1s"
            // data-wow-delay="1s"
            // data-wow-offset='100'
            // data-wow-iteration='1'
            >
            <Typography id='prom' component='h2' sx={{ ...visuallyHidden }}>Promotion</Typography>
            <Marquee autoFill>
                <img src={Marque} alt='' />
            </Marquee>
        </Container>
    );
}

export default MarqueeScroll;