import React, { useState, useEffect, useRef } from 'react';
import { WOW } from 'wowjs';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Footer from '../components/footer/footer.jsx';
import { GlobalTheme } from '../theme.js';
import useMediaQuery from '@mui/material/useMediaQuery';

import axios from 'axios'; // For API request
import Link from '@mui/material/Link';
import { Link as bAlink } from 'react-router-dom';
import PlpAllStyle from './assets/scss/plpAll.module.scss'
import HelMet from '../components/helmet.jsx';
const PlpAll = ({ gridHeight }) => {
    const plHeBaRef = useRef([]);
    const isDesktop = useMediaQuery(GlobalTheme.breakpoints.up('lg'));
    const [animate, setAnimate] = useState(false);
    const baseImageUrl = 'https://bankacom.inpro1.fcomet.com/api/cmsapi/uploads';
    const baseBImageUrl = 'https://bankacom.inpro1.fcomet.com/api/backend/uploads';
    // Fetch products from API and update images array
    const [category, setcategory] = useState([]);
    const [slidesData, setSlidesData] = useState([]);

    useEffect(() => {
        axios
            .get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_category`)
            .then((response) => {
                const filteredData = response.data.data; // Filter out records where id === 1
                setcategory(filteredData); // Save the filtered data

            })
            .catch((error) => {
                console.error('Error fetching products:', error);
            });

    }, []);
    const fetchSlidesData = async () => {
        try {
            const response = await axios.get('https://bankacom.inpro1.fcomet.com/backend/table-data/banka_popular_banner');
            const filteredData = response.data.data.filter(slide => slide.id !== 1); // Filter out records where id === 1
            setSlidesData(filteredData); // Save the filtered data
        } catch (error) {
            console.error('Error fetching home banner data:', error);
        }
    };
    useEffect(() => {
        fetchSlidesData();

    }, []);

    // useEffect(() => {
    //     if (plHeBaRef.current) {
    //         plHeBaRef.current.style.height = `${gridHeight}px`; // Set height from prop
    //     }
    // }, [gridHeight]); // Recalculate on gridHeight change

    useEffect(() => {
        if (plHeBaRef.current.length > 0) {
            plHeBaRef.current.forEach((ref) => {
                if (ref) {
                    ref.style.height = `${gridHeight}px`; // Set height for each grid
                }
            });
        }
    }, [gridHeight]);

    useEffect(() => {
        // Ensure all refs are initialized
        plHeBaRef.current = plHeBaRef.current.slice(0, category.length).map(
            (ref, index) => ref || React.createRef()
        );
    }, [category]);

    useEffect(() => {
        // Trigger animation once the component mounts
        const timeout = setTimeout(() => {
            setAnimate(true);
        }, 0); // Delay can be adjusted as needed
        return () => clearTimeout(timeout); // Cleanup
    }, []);

    useEffect(() => {
        const wow = new WOW({
            boxClass: 'wow',       // Class to identify elements to animate
            animateClass: 'animate__animated', // Animation class from Animate.css
            offset: 100,           // Distance to start the animation (in px)
            mobile: false,          // Enable animations on mobile
            live: true,            // Recheck for new elements to animate on the page
        });
        wow.init(); // Initialize WOW.js
    }, []);

    return (
        <Container disableGutters maxWidth='false' id='plAlBa'>
            <HelMet page_name="collection" />
            <Grid id='b1Ba' disableGutters container>
                <Container disableGutters maxWidth={false} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {category.map((category, index) => (
                        <Grid id={`plHeBa${index}`}
                            ref={(el) => {
                                plHeBaRef.current[index] = el; // Assign each Grid element to the corresponding ref
                            }}
                            key={index} size={{ xs: 12, md: 6 }} position='relative'>
                            <Card sx={{
                                borderRadius: 0,
                                transition: 'scale 1s ease-in',

                                "&:hover": {
                                    "& .zoom": {
                                        transform: category.category === 'Ornaments' ? 'scale(1.2) rotate(5deg)' : 'scale(1.2)',
                                    }
                                }
                            }}>
                                <Box position='absolute' height='100%' width='100%'>
                                    <CardContent
                                        // className="wow animate__animated animate__fadeIn"
                                        // data-wow-duration="1s"
                                        // data-wow-delay="1s"
                                        // data-wow-offset='100'
                                        // data-wow-iteration='1'
                                        sx={{
                                            height: '100%',
                                            py: {
                                                xs: 18.75,
                                                lg: 12.5
                                            },
                                            px: {
                                                lg: 12.5
                                            },
                                            textAlign: { xs: 'center', lg: 'revert' },
                                            position: 'relative',
                                            zIndex: 1
                                        }}>
                                        <Typography gutterBottom component="h2" fontWeight={{
                                            xs: 600,
                                            lg: 500
                                        }}
                                            className={animate && isDesktop ? 'animate__animated animate__fadeInUp animate__delay-1s' : ''}
                                            sx={{
                                                background: category.category === 'Ornaments' ? 'linear-gradient(55deg, #0E68A9, #49AAF0)' : 'linear-gradient(55deg, #FBE7CF, #F9D8AF)',
                                                WebkitBackgroundClip: 'text',
                                                WebkitTextFillColor: 'transparent',
                                                backgroundSize: '200% 200%',
                                                transition: 'background-position 0.5s ease',
                                                textDecoration: 'none',
                                                display: 'inline-block',
                                                fontSize: {
                                                    xs: '3.4rem',
                                                    sm: '4.5rem',
                                                    lg: '6rem'
                                                },
                                                lineHeight: {
                                                    xs: .9,
                                                    lg: 'revert'
                                                }
                                            }}>
                                            {category.category}
                                        </Typography>
                                        <CardActions sx={{
                                            position: 'absolute', bottom: 40,
                                            right: {
                                                xs: 0,
                                                lg: 70
                                            },
                                            width: {
                                                xs: '100%',
                                                lg: 'auto'
                                            },
                                            display: 'flex',
                                            justifyContent: {
                                                xs: 'center',
                                            }
                                        }}>
                                            <Link component={bAlink} id={`vCBa${index}`} to={`/PlpCategory?category_id=${category.id}`}
                                                style={{
                                                    textDecoration: 'none',
                                                    color: category.category === 'Ornaments' ? GlobalTheme.palette.common.white : GlobalTheme.palette.secondary.dark,
                                                }}
                                                className={`${PlpAllStyle.btnStyle} ${category.category === 'Ornaments' ? PlpAllStyle.ornamentsBtn : PlpAllStyle.chainsBtn}`}
                                            >
                                                View Collection
                                            </Link>
                                        </CardActions>
                                    </CardContent>
                                </Box>

                                <CardMedia
                                    sx={{
                                        height: gridHeight,
                                        transition: 'transform 3s ease-in-out',
                                    }}
                                    loading='lazy'
                                    className='zoom'
                                    image={`${baseImageUrl}/${isDesktop ? category.category_thumbnail : category.category_mobile_thumbnail}`}
                                />
                            </Card>
                        </Grid>
                    ))}

                </Container>
            </Grid >

            <Grid id='b2Ba' disableGutters container>
                {slidesData.map((slide, index) => (
                    <Grid key={index} size={{ xs: 12 }} position='relative'>
                        <Card id='a' elevation={0}
                            // className="wow animate__animated animate__fadeIn"
                            // data-wow-duration="1s"
                            // data-wow-delay="1s"
                            // data-wow-offset='100'
                            // data-wow-iteration='1'
                            sx={{
                                borderRadius: 0,
                                transition: 'all 5s ease-in',

                                "&:hover": {
                                    "& .zoom": {
                                        transform: 'scale(1.09) translate(2%, -3%)',
                                        rotate: '.1deg',
                                    }
                                }
                            }}>
                            <Box position='absolute' height='100%' width='100%'
                                sx={{
                                    px: {
                                        xs: 3.25,
                                        lg: 6.5
                                    },
                                    py: {
                                        xs: 12.5,
                                    }
                                }}>
                                <CardContent sx={{ position: 'relative', height: '100%', zIndex: 1, textAlign: { xs: 'center', lg: 'revert' } }}>
                                    <Typography gutterBottom component="h2"
                                        className={animate && isDesktop ? 'animate__animated animate__fadeInUp animate__delay-1s' : ''}
                                        fontWeight={500}
                                        sx={{
                                            background: 'linear-gradient(45deg, #fff, #fff)',
                                            WebkitBackgroundClip: 'text',
                                            WebkitTextFillColor: 'transparent',
                                            backgroundSize: '100% 100%', // Make the gradient larger than the element
                                            transition: 'background-position 0.5s ease', // Smooth transition for the background
                                            textDecoration: 'none', // Remove underline if needed
                                            display: 'inline-block',
                                            fontSize: {
                                                xs: '3.4rem',
                                                sm: '4.5rem',
                                                lg: '6rem'
                                            },
                                            lineHeight: {
                                                xs: .9,
                                                lg: 'revert'
                                            }
                                        }}
                                    >
                                        Most Popular
                                    </Typography>

                                    <CardActions sx={{
                                        position: 'absolute', bottom: 0,
                                        right: 0,
                                        width: {
                                            xs: '100%',
                                            lg: 'auto'
                                        },
                                        display: 'flex',
                                        justifyContent: {
                                            xs: 'center',
                                        }
                                    }}>
                                        <Link component={bAlink} id={`viCaBa${index}`} key={index} to={`/PopularProduct`}
                                            className={`${PlpAllStyle.btnStyle} ${PlpAllStyle.mostPopBtn}`}
                                            style={{
                                                color: GlobalTheme.palette.secondary.dark
                                            }}
                                        >
                                            View Collection
                                        </Link>
                                    </CardActions>
                                </CardContent>
                            </Box>
                            <CardMedia
                                sx={{
                                    height: 637, borderRadius: 0,
                                    transition: 'transform 3s ease-in-out',
                                }}
                                loading='lazy'
                                className='zoom'
                                image={`${baseBImageUrl}/${slide.image_collection_page_banner}`}
                            />

                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Footer />
        </Container >
    )
}

export default PlpAll;