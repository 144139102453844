import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios'; // Import axios for API calls
import { Swiper, SwiperSlide } from 'swiper/react';

import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import { Box, Divider, Typography } from '@mui/material';
import { Autoplay } from 'swiper/modules';
import Intro from './assets/video/intro.mp4';
import HomeStyle from './assets/scss/home.module.scss';
import { common } from '@mui/material/colors';
import { GlobalTheme } from '../../theme';
import useMediaQuery from '@mui/material/useMediaQuery';

const baseImagePath = 'https://bankacom.inpro1.fcomet.com/api/backend/uploads';

const AnimatedMantle = ({ gridHeight }) => {
    const plHeBaRef = useRef(null);
    const isMobile = useMediaQuery(GlobalTheme.breakpoints.down('lg'));
    const isDesktop = useMediaQuery(GlobalTheme.breakpoints.up('md'));
    const [imageHeight, setImageHeight] = useState(0);
    const [slidesData, setSlidesData] = useState([]);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const progressBarRef = useRef(null); // Ref for progress bar
    // Refs for the DOM elements you want to animate
    const txtHeaBaRef = useRef(null);
    const txtSubHeaBaRef = useRef(null);
    const dividerBaRef = useRef(null);
    const txtDescBaRef = useRef(null);

    const isXs = useMediaQuery(GlobalTheme.breakpoints.down('lg'));

    const animationClass = isXs ? 'animate__slideInLeft' : 'animate__slideInRight';

    // Function to fetch data from the API
    const fetchSlidesData = async () => {
        try {
            const response = await axios.get('https://bankacom.inpro1.fcomet.com/backend/table-data/banka_home_banner');
            const filteredData = response.data.data.filter(slide => slide.id !== 1); // Filter out records where id === 1
            setSlidesData(filteredData); // Save the filtered data
        } catch (error) {
            console.error('Error fetching home banner data:', error);
        }
    };

    const calculateImageHeight = () => {
        if (plHeBaRef.current) {
            const boxHeight = plHeBaRef.current.offsetHeight;
            setImageHeight(boxHeight); // Set the height of the image
        }
    };

    useEffect(() => {
        fetchSlidesData();
    }, []);

    // Handle image loading and trigger animations
    const handleImageLoad = () => {
        setImageLoaded(true);
        // Trigger text slide-out animations after 4 seconds
        setTimeout(() => {
            triggerSlideOutAnimations();
        }, 4000);
    };

    // Trigger the slide-out animations
    const triggerSlideOutAnimations = () => {
        if (txtHeaBaRef.current) txtHeaBaRef.current.classList.add(HomeStyle.slideOutTop);
        if (txtSubHeaBaRef.current) txtSubHeaBaRef.current.classList.add(HomeStyle.slideOutLeft);
        // if (dividerBaRef.current) dividerBaRef.current.classList.add(HomeStyle.fadeInTop);
        if (txtDescBaRef.current) txtDescBaRef.current.classList.add(HomeStyle.fadeInTop);
    };

    const triggerAnimations = (index) => {
        const txtHeaBa = document.getElementById(`txtHeaBa-${index}`);
        const txtSubHeaBa = document.getElementById(`txtSubHeaBa-${index}`);
        const dividerBa = document.getElementById(`dividerBa-${index}`);
        const txtDescBa = document.getElementById(`txtDescBa-${index}`);

        if (txtHeaBa) txtHeaBa.classList.add('animate__fadeInDown');
        if (txtSubHeaBa) txtSubHeaBa.classList.add('animate__fadeInLeft');
        if (dividerBa) dividerBa.classList.add('animate__fadeInLeft');
        if (txtDescBa) txtDescBa.classList.add('animate__fadeInUp');
    };
    // Handle slide change with WOW sync
    const handleSlideChange = (swiper) => {
        setCurrentSlideIndex(swiper.realIndex);
    };

    // Trigger animations when slidesData is loaded for the first time
    useEffect(() => {
        if (slidesData.length > 0) {
            triggerAnimations(0); // Trigger animations for the first slide
        }
    }, [slidesData]);

    useEffect(() => {
        triggerAnimations(currentSlideIndex);
    }, [currentSlideIndex]);

    // Monitor the progress bar to trigger animations at 85%
    useEffect(() => {
        const progressBar = progressBarRef.current;
        if (!progressBar) return; // Ensure the progress bar exists

        const checkProgress = () => {
            const progress = progressBar.offsetWidth / progressBar.parentElement.offsetWidth;
            if (progress >= 0.85) {
                triggerSlideOutAnimations(); // Trigger animations when the progress bar reaches 85%
            }
        };

        const intervalId = setInterval(checkProgress, 100); // Check the progress every 100ms
        return () => clearInterval(intervalId); // Cleanup the interval on unmount
    }, [currentSlideIndex]); // Re-run when the current slide changes

    useEffect(() => {
        calculateImageHeight();
        const resizeObserver = new ResizeObserver(calculateImageHeight);

        if (plHeBaRef.current) {
            const boxHeight = plHeBaRef.current.offsetHeight; resizeObserver.observe(plHeBaRef.current);
            setImageHeight(boxHeight);
            plHeBaRef.current.style.height = `${gridHeight}px`; // Set height from prop
        }
        return () => {
            resizeObserver.disconnect();
        };
    }, [gridHeight]); // Recalculate on gridHeight change

    return (
        <Box id='hoMaBa' ref={plHeBaRef} component='section' position='relative'
            sx={{
                // height: imageHeight,
                // height: 487,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
                animation: 'fadeIn 2s ease-in-out'
            }}>
            <Box display='flex' justifyContent='center'>
                <Box
                    id='introVid'
                    position='absolute'
                    bottom={{
                        xs: -174,
                        sm: -87,
                        md: -90,
                        lg: -82,
                        xl: -94
                    }}
                    zIndex={2}
                    className={HomeStyle.logo_mask}
                    sx={{
                        transform: {
                            xs: 'scale(.5)',
                            sm: 'scale(1)',
                            md: 'scale(.8)',
                            lg: 'scale(1)',
                        }
                    }}
                >
                    <Box className={HomeStyle.video_holder}>
                        <CardMedia
                            id="mantleMedia"
                            component="video"
                            height="416"
                            autoPlay
                            loop
                            muted
                            playsInline
                            loading='lazy'
                            sx={{
                                transform: 'scale(1.5)'
                            }}
                        >
                            <source src={Intro} type="video/mp4" />
                        </CardMedia>
                    </Box>
                </Box>
            </Box>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: true,
                }}
                onSlideChange={handleSlideChange} // Reset on each slide change
                modules={[Autoplay]}
                className={HomeStyle.adjMantleBottom}
            >
                {slidesData.map((slide, index) => (
                    <SwiperSlide id={`mantle${index}`} key={index}>
                        <Box display='flex' flexWrap='wrap' justifyContent='center' position='relative' paddingTop={1}>
                            <Stack direction={{
                                xs: 'column',
                                md: 'row'
                            }} position='relative'>
                                <Box id='forMobile' display={{
                                    xs: 'block',
                                    md: 'none'
                                }} position='absolute' bottom={0} left={0} right={0} width='100%' height='100%'
                                    sx={{
                                        px: 4.5,
                                        margin: '0 auto',
                                        transform: {
                                            xs: 'translateY(140px)',
                                            sm: 'translateY(200px)'
                                        }
                                    }}>
                                    <Typography variant="h1" color={common.white} fontWeight={200}
                                        sx={{
                                            fontSize: {
                                                xs: '2rem',
                                                sm: '3rem'
                                            },
                                        }}
                                    >
                                        <Stack component='span'>
                                            <Box component='span'
                                                id={`txtHeaBa-${index}`}
                                                ref={txtHeaBaRef}
                                                className='animate__animated'
                                                data-wow-duration="2s"
                                                position='relative'
                                                sx={{
                                                    top: 13
                                                }}
                                            >
                                                {slide.heading}
                                            </Box>
                                            <Typography
                                                id={`txtSubHeaBa-${index}`}
                                                ref={txtSubHeaBaRef}
                                                fontSize={{
                                                    xs: '4rem',
                                                    sm: '6rem'
                                                }}
                                                component='span'
                                                fontWeight={500}

                                                sx={{
                                                    fontFamily: 'OPTIOnyx',
                                                }}
                                                className='animate__animated'
                                                data-wow-duration="2s"
                                            >
                                                {slide.sub_heading}
                                            </Typography>
                                        </Stack>
                                    </Typography>
                                    <Stack direction='row' spacing={2} alignItems='center'>
                                        <Divider
                                            id='dividerBa'
                                            ref={dividerBaRef}
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    md: 'flex'
                                                },
                                                borderColor: common.white, width: '24%', height: 2
                                            }}
                                            className={`wow animate__animated ${animationClass}`}
                                        />
                                        <Box width={{
                                            xs: '60%',
                                            sm: '80%'
                                        }}
                                            id={`txtDescBa-${index}`} className="animate__animated ">
                                            <Typography
                                                component='p'
                                                color={common.white}
                                                fontWeight={300}
                                                sx={{
                                                    fontSize: {
                                                        xs: '.75rem',
                                                        sm: '1.4rem'
                                                    },
                                                }}>
                                                {slide.description}
                                            </Typography>
                                        </Box>
                                    </Stack>

                                </Box>

                                <Box
                                    id='forDesktopA'
                                    position='absolute'
                                    display={{
                                        xs: 'none',
                                        md: 'block'
                                    }}
                                    bottom={{
                                        md: '1.5rem'
                                    }}
                                    left={0}
                                    right={{ xs: 0 }}
                                    width={{
                                        md: '36%',
                                    }}
                                    textAlign={{ md: 'right' }}
                                    sx={{
                                        transform: {
                                            md: 'translateX(-56px)',
                                        },
                                        margin: {
                                            xs: '0 auto',
                                            md: 'unset'
                                        },
                                        zIndex: 10
                                    }}>
                                    <Typography variant="h1" color={common.white} fontWeight={200}
                                        sx={{
                                            fontSize: {
                                                md: '2rem',
                                                lg: '3rem',
                                                // lg: '1.8rem',
                                            },
                                        }}
                                    >
                                        <Stack component='span'>
                                            <Box component='span'
                                                id={`txtHeaBa-${index}`}

                                                className='animate__animated'

                                                position='relative'
                                                sx={{
                                                    top: 13
                                                }}
                                            >
                                                {slide.heading}
                                            </Box>
                                            <Typography
                                                id={`txtSubHeaBa-${index}`}

                                                variant="h2"
                                                component='span'
                                                fontWeight={500}

                                                sx={{
                                                    fontFamily: 'OPTIOnyx',
                                                    fontSize: {
                                                        md: '3.4rem',
                                                        lg: '4rem',
                                                        xl: '5rem',
                                                    },
                                                    mb: 1.25
                                                }}
                                                className='animate__animated'

                                            >
                                                {slide.sub_heading}
                                            </Typography>
                                        </Stack>
                                    </Typography>
                                </Box>
                                <Box id='forDesktopB' position='absolute'
                                    display={{
                                        xs: 'none',
                                        md: 'block'
                                    }} bottom={{ md: 40 }} right={0} width={{ md: '36%', zIndex: 10 }}
                                    sx={{
                                        transform: {
                                            lg: 'translate(-7px, -50px)',
                                            // lg: 'translate(-25px, -28px)',
                                            xl: 'translate(-30px, -30px)',
                                        }
                                    }}>
                                    <Stack direction='row' spacing={2} alignItems='center'>
                                        <Divider
                                            id={`dividerBa-${index}`}

                                            sx={{ borderColor: common.white, width: '42%', height: 2 }}
                                            className="animate__animated"
                                        />
                                        <Box width='50%' id={`txtDescBa-${index}`} className="animate__animated ">
                                            <Typography variant="h4" component='p' color={common.white} fontWeight={200}
                                                textAlign='left'
                                                sx={{
                                                    fontSize: {
                                                        xs: '.75rem',
                                                        lg: '1.125rem',
                                                        // lg: '.875rem',
                                                        xl: '1.125rem',
                                                    }
                                                }}>
                                                {slide.description}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </Box>

                                <img
                                    src={`${baseImagePath}/${isDesktop ? slide.image_Banner : slide.image_banner_mobile}`}
                                    alt={slide.heading}
                                    onLoad={handleImageLoad}
                                    style={{
                                        // height: imageHeight,
                                        objectFit: 'cover',
                                        width: '100%',

                                    }}
                                    className={HomeStyle.image}
                                />
                            </Stack>
                        </Box>
                        {/* Progress bar */}
                        {/* <Box className={HomeStyle.progressBar} >
                            <Box ref={progressBarRef} className={HomeStyle.progressBarFill}></Box>
                        </Box> */}
                    </SwiperSlide >
                ))}
            </Swiper >
        </Box >
    );
};

export default AnimatedMantle;
