import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CardMedia, IconButton, Box } from "@mui/material";
import { alpha } from '@mui/material/styles'
import axios from 'axios';
import { WOW } from 'wowjs';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

import "./assets/scss/slider.scss";
import { GlobalTheme } from "../theme";

function CenterMode() {
    const baseImageUrl = 'https://bankacom.inpro1.fcomet.com/api/cmsapi/uploads';
    const [subcategories, setSubcategories] = useState([]);
    const [products, setProducts] = useState([]);
    const categoryIdFromUrl = 1; // Replace with logic to get this from URL
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const fetchSubcategories = axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_sub_category`);
        const fetchProducts = axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_product`);

        Promise.all([fetchSubcategories, fetchProducts])
            .then(([subcategoryResponse, productResponse]) => {
                setSubcategories(subcategoryResponse.data.data);
                setProducts(productResponse.data.data);
            })
            .catch((error) => console.error('Error fetching data:', error));
    }, [categoryIdFromUrl]);

    const filteredProducts = products.filter(product => {
        const subcategory = subcategories.find(subcat => subcat.id === product.sub_category_id && subcat.category_id === categoryIdFromUrl);
        return subcategory && product.show_in_spectrum === 1;
    });

    const PrevArrow = ({ onClick }) => (
        <Box sx={{
            position: 'absolute',
            top: 0,
            left: 10,
            width: '3%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            zIndex: 1
        }}>
            <IconButton className="prev-arrow" onClick={onClick} sx={{
                color: GlobalTheme.palette.common.white,
                "&:hover": {
                    color: GlobalTheme.palette.common.black,
                    backgroundColor: GlobalTheme.palette.common.white
                }
            }} >
                <KeyboardArrowLeftOutlinedIcon />
            </IconButton>
        </Box>
    );

    const NextArrow = ({ onClick }) => (
        <Box sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: '3%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            zIndex: 1
        }}>
            <IconButton className="next-arrow" onClick={onClick} sx={{
                color: GlobalTheme.palette.common.white,
                "&:hover": {
                    color: GlobalTheme.palette.common.black,
                    backgroundColor: GlobalTheme.palette.common.white
                }
            }}>
                <KeyboardArrowRightOutlinedIcon />
            </IconButton>
        </Box>
    );

    const settings = {
        className: "center slider variable-width",
        centerMode: true,
        infinite: true,
        slidesToShow: 1,
        speed: 1500,
        focusOnSelect: true,
        slidesToScroll: 1,
        autoplay: true,
        variableWidth: true,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        beforeChange: (current, next) => setActiveIndex(next),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    useEffect(() => {
        const wow = new WOW({
            boxClass: 'wow',       // Class to identify elements to animate
            animateClass: 'animate__animated', // Animation class from Animate.css
            offset: 100,           // Distance to start the animation (in px)
            mobile: false,          // Enable animations on mobile
            live: true,            // Recheck for new elements to animate on the page
        });
        wow.init(); // Initialize WOW.js
    }, []);


    return (
        <div
            // className="wow animate__animated animate__fadeInUp "
            // data-wow-duration="1s"
            // data-wow-delay="1s"
            // data-wow-offset='100'
            // data-wow-iteration='1'
            >
            <Slider id='sOcBaSli' {...settings}>

                {filteredProducts.map((item, index) => (
                    <Box
                        id={`sOcBaSli${index}`}
                        key={item.id || index}
                        sx={{
                            margin: '0 auto',
                            transition: 'width 0.3s ease',
                        }}
                    >
                        <CardMedia
                            id={`sOcImBa${index}`}
                            component="img"
                            image={`${baseImageUrl}/${item.product_image}`}
                            alt={item.product_name}

                            loading='lazy'

                            sx={{
                                width: {
                                    xs: 598,
                                    lg:830
                                },
                                height: {
                                    xs: 529,
                                    lg:361
                                },
                                borderRadius: 3.5,
                            }}
                        />
                    </Box>
                ))}

            </Slider>
        </div>
    );
}

export default CenterMode;
