import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Parallax } from 'react-parallax';
import useMediaQuery from '@mui/material/useMediaQuery';
import { WOW } from 'wowjs';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Footer from '../components/footer/footer.jsx';
import { GlobalTheme } from '../theme';
import EventBanner from './assets/images/eventsBanner.jpg';
import Card from '@mui/material/Card';
import { Autoplay } from 'swiper/modules';
import { useLocation } from 'react-router-dom';
import './assets/scss/event.scss';
import HelMet from '../components/helmet.jsx';
import EventSlider from './eventsSlider.jsx';

const Events = ({ gridHeight }) => {
    const plHeBaRef = useRef(null);
    const isMobile = useMediaQuery(GlobalTheme.breakpoints.down('lg'));
    const isDesktop = useMediaQuery(GlobalTheme.breakpoints.up('lg'));
    const [scrollY, setScrollY] = useState(0);

    const [eventdata, setEventData] = useState([]);
    const [eventparticipation, setEventParticipation] = useState([]);
    const [eventsnap, setEventSnap] = useState([]);

    const location = useLocation();

    const baseImageUrl = 'https://bankacom.inpro1.fcomet.com/api/cmsapi/uploads';
    const baseBImageUrl = 'https://bankacom.inpro1.fcomet.com/api/backend/uploads';

    const getCols = () => {
        if (isMobile) return 1;
        if (isDesktop) return 3;
        return 3;
    };

    // Fetch categories, subcategories, and products
    useEffect(() => {

        const eventdata = axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_event_data`);
        const eventparticipation = axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_event_participation`);
        const eventsnap = axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_event_snapshots`);

        Promise.all([eventdata, eventparticipation, eventsnap])
            .then(([edResponse, epResponse, esRespomse]) => {

                const filteredeventdata = edResponse.data.data.filter(about => about.id !== 1); // Filter out records where id === 1
                const filteredeventparticipation = epResponse.data.data.filter(about => about.id !== 1); // Filter out records where id === 1
                const filteredeventsnap = esRespomse.data.data.filter(about => about.id !== 1); // Filter out records where id === 1

                setEventData(filteredeventdata);
                setEventParticipation(filteredeventparticipation);
                setEventSnap(filteredeventsnap);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    useEffect(() => {
        if (plHeBaRef.current) {
            plHeBaRef.current.style.height = `${gridHeight}px`; // Set height from prop
        }
    }, [gridHeight]); // Recalculate on gridHeight change

    useEffect(() => {
        const wow = new WOW({
            boxClass: 'wow',       // Class to identify elements to animate
            animateClass: 'animate__animated', // Animation class from Animate.css
            offset: 100,           // Distance to start the animation (in px)
            mobile: false,          // Enable animations on mobile
            live: true,            // Recheck for new elements to animate on the page
        });
        wow.init(); // Initialize WOW.js
    }, []);
    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    return (
        <>
            <HelMet page_name="events" />
            <Grid container spacing={2}>
                <Box id='plHeBa' component='section' aria-labelledby='plpBa' width='100%' position='relative'>
                    {eventdata.map((item) => (
                        <>
                            <Card sx={{ position: 'relative', height: 'inherit', borderRadius: 0 }}
                                // className="wow animate__animated animate__fadeIn"
                                data-wow-duration="1s"
                                data-wow-delay="1s"
                                data-wow-offset='100'
                                data-wow-iteration='1'>
                                <Parallax
                                    blur={isDesktop ? { min: Math.min(scrollY / 10, -15), max: Math.min(scrollY / 5, 15) } : ''}
                                    strength={-100}
                                    className='mantleImg'

                                    bgImage={`${baseBImageUrl}/${isDesktop ? item.image_event_desktop_banner : item.image_event_mobile_banner}`}
                                    style={{
                                        backgroundColor: GlobalTheme.palette.common.black
                                    }}
                                // title={subcategories[0].sub_category_heading || ''} // Optional: Set the title to sub_category_heading
                                >
                                </Parallax>


                                <Container maxWidth='false' disableGutters
                                    sx={{
                                        justifyContent: 'center',
                                        textAlign: 'center',
                                        pt: 7.37,
                                        pb: 7.5,
                                        background: GlobalTheme.palette.common.black
                                    }}
                                >
                                    <Typography component='h2' variant='h2' color='primary.light' position='relative'
                                        // className="wow animate__animated animate__fadeInUp"
                                        data-wow-duration="1s"
                                        data-wow-delay="1s"
                                        data-wow-offset='100'
                                        data-wow-iteration='1'
                                        sx={{
                                            position: 'relative',
                                            fontSize: {
                                                xs: '1.375rem',
                                                lg: '2.25rem'
                                            },

                                            "&:before": {
                                                content: '""',
                                                height: 2,
                                                width: '100%',
                                                position: 'absolute',
                                                transform: {
                                                    xs: 'translateY(12px)',
                                                    lg: 'translateY(22px)'
                                                },
                                                backgroundColor: 'primary.main',
                                                left: 0, right: 0
                                            }
                                        }}>
                                        <Box component='span' position='relative' display='flex' justifyContent='center' sx={{
                                            backgroundColor: GlobalTheme.palette.common.black,
                                            width: {
                                                xs: '60%',
                                                lg: '80%',
                                                lg: '45%',
                                                xl: '35%',
                                            },
                                            zIndex: 1, margin: '0 auto'
                                        }}>

                                            {item.event_page_heading || 'No Title'}

                                        </Box>
                                    </Typography>

                                    <Container fixed>
                                        <Typography component='p' color='secondary.light' fontWeight={200} marginTop={3}
                                            // className="wow animate__animated animate__fadeInUp"
                                            data-wow-duration="1s"
                                            data-wow-delay="1s"
                                            data-wow-offset='100'
                                            data-wow-iteration='1'
                                            sx={{
                                                fontSize: {
                                                    xs: '.75rem',
                                                    lg: '1.375rem'
                                                }

                                            }}>
                                            {item.event_page_description || 'No Description'}
                                        </Typography>
                                    </Container>
                                </Container>
                            </Card >
                        </>
                    ))}
                </Box>

                <Container disableGutters maxWidth={false} sx={{
                    mt: {
                        xs: 8.125,
                        lg: 8.75
                    },
                    mb: {
                        xs: 8.125,
                        lg: 8.75
                    },
                    width: {
                        xs: '75%',
                        lg: '100%'
                    }
                }}>
                    <EventSlider />
                </Container>

                <Box>
                    <ImageList variant="masonry" cols={getCols()} gap={10} className="image-item">
                        {eventsnap.map((item) => (
                            <>                                {
                                item.image_snapshot && (<ImageListItem key={item.img}>
                                    <img
                                        srcSet={`${baseBImageUrl}/${item.image_snapshot}`}
                                        src={`${baseBImageUrl}/${item.image_snapshot}`}
                                        alt={item?.snapshot_alt}
                                        loading="lazy"
                                        // className="wow animate__animated animate__flipInY"
                                        data-wow-duration="1s"
                                        data-wow-delay="1s"
                                        data-wow-offset='100'
                                        data-wow-iteration='1'
                                    />
                                </ImageListItem>
                                )
                            }
                            </>
                        ))}
                    </ImageList>
                </Box>
            </Grid >
            <Footer />
        </>
    );
};

export default Events;
