import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';

function HelMet({ page_name }) {
    const [metaData, setMetaData] = useState({
        title: 'Welcome to Banka: Crafting timeless jewelry since 1964',
        description: 'Discover the exceptional jewelry from Banka 1964, a legacy of elegance and innovation.',
        keywords: 'Banka, jewelry, legacy, Sambalpur',
    });

    useEffect(() => {
        // Fetch data from the API
        const fetchMetaData = async () => {
            try {
                const response = await axios.get('https://bankacom.inpro1.fcomet.com/backend/table-data/banka_helmet');
                const data = response.data?.data || [];

                // Find the meta data for the current page
                const pageData = data.find((item) => item.page_name === page_name);

                if (pageData) {
                    setMetaData({
                        title: pageData.title || metaData.title,
                        description: pageData.description || metaData.description,
                        keywords: pageData.keywords || metaData.keywords,
                    });
                }
            } catch (error) {
                console.error('Error fetching meta data:', error);
            }
        };

        fetchMetaData();
    }, [page_name]);

    return (
        <Helmet>
            <title>{metaData.title}</title>
            <meta name="description" content={metaData.description} />
            <meta name="keywords" content={metaData.keywords} />
        </Helmet>
    );
}

export default HelMet;
