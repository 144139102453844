import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ scrollContainerRef }) => {
  const location = useLocation();

  useEffect(() => {
    if (scrollContainerRef?.current) {
      scrollContainerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth', // Optional smooth scrolling
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [location.pathname,location.search, scrollContainerRef]); // Trigger on pathname change

  return null; // This component does not render anything
};

export default ScrollToTop;
