import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CardMedia, IconButton, Box } from "@mui/material";
import { alpha } from '@mui/material/styles'
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

import "./assets/scss/slider.scss";
import { GlobalTheme } from "../theme";

function CenterMode() {
    const baseImageUrl = 'https://bankacom.inpro1.fcomet.com/api/cmsapi/uploads';
    const [subcategories, setSubcategories] = useState([]);
    const [products, setProducts] = useState([]);
    const categoryIdFromUrl = 1; // Replace with logic to get this from URL
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const fetchSubcategories = axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_sub_category`);
        const fetchProducts = axios.get(`https://bankacom.inpro1.fcomet.com/backend/table-data/banka_product`);

        Promise.all([fetchSubcategories, fetchProducts])
            .then(([subcategoryResponse, productResponse]) => {
                setSubcategories(subcategoryResponse.data.data);
                setProducts(productResponse.data.data);
            })
            .catch((error) => console.error('Error fetching data:', error));
    }, [categoryIdFromUrl]);

    const filteredProducts = products.filter(product => {
        const subcategory = subcategories.find(subcat => subcat.id === product.sub_category_id && subcat.category_id === categoryIdFromUrl);
        return subcategory && product.show_in_spectrum === 1;
    });

    return (
        <Swiper
            slidesPerView={1}
            spaceBetween={10}
            grabCursor={true}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="spectrumSlider"
            breakpoints={{
                600: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                900: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                },
                1024: {
                    slidesPerView: 5,
                    spaceBetween: 50,
                },
            }}
        >
            {filteredProducts.map((item, index) => (
                <SwiperSlide id={`sOcBaSli${index}`}
                    key={item.id || index}
                    style={{
                        margin: '0 auto',
                        transition: 'width 0.3s ease',
                    }}>

                    <CardMedia
                        id={`sOcImBa${index}`}
                        component="img"
                        image={`${baseImageUrl}/${item.product_image}`}
                        alt={item.product_name}

                        loading='lazy'

                        sx={{
                            width: '100%',
                            maxWidth: 317,
                            height: {
                                xs: 441,
                                sm: 200

                            },
                            borderRadius: 3.5,
                        }}
                    />

                </SwiperSlide>
            ))
            }
        </Swiper >

    );
}

export default CenterMode;
